<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12 text-center font-weight-800" v-if="record.is_sanctioned">
                        <base-alert type="danger">
                            <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                            <span class="alert-inner--text">
                            Doctor has been sanctioned
                            </span>
                        </base-alert>
                        <br>
                    </div>
                    <div class="col-lg-12 text-center font-weight-800" v-if="record.is_deceased">
                        <base-alert type="danger">
                            <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                            <span class="alert-inner--text">
                                Doctor is Deceased
                            </span>
                        </base-alert>
                        <br>
                    </div>

                    <div 
                        class="col-lg-12 text-center font-weight-800" 
                        v-if="record && record.user && (record.user.status == 'suspend')"
                    >
                        <base-alert type="danger">
                            <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                            <span class="alert-inner--text">
                                Doctor has been Suspended
                            </span>
                        </base-alert>
                        <br>
                    </div>

                    <div class="col-lg-4">
                        <div class="card-profile-image marginTop40">
                            <img
                                :src="getUserPix(record)"
                                class="rounded-circle doctor-image"
                                @error="replaceByDefault"
                            >
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Surname (Last Name)"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.last_name"
                                />
                            </div>
                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="First Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.first_name"
                                />
                            </div>
                            <div class="col-lg-4">
                                <base-input alternative=""
                                    label="Other Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.other_name"
                                />
                            </div>
                            <div class="col-lg-6">
                                <base-input label="Account Status">
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.user.status"
                                    >
                                        <option  value="null" disabled>
                                            ---Select One---
                                        </option>
                                        <option value="pending">Pending</option>
                                        <option value="active">Active</option>
                                        <option value="suspend">Suspended</option>
                                    </select>
                                </base-input>
                            </div>
                            <div class="col-lg-6">
                                <base-input label="New Account">
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.user.new_account"
                                    >
                                        <option  value="null" disabled>
                                            ---Select One---
                                        </option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </base-input>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-4">
                        <base-input alternative="" 
                            label="Previous Name"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.previous_names"
                            placeholder="Last Name, First Name, Other Name"
                            :readonly="! isHodOrSuperOrUnitHead"
                        />
                    </div>
                    <div class="col-lg-4">
                        <base-input label="Marital Status">
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.marital_status"
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="divorced">Divorced</option>
                                <option value="widow">Widow</option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Registration Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                @change="getSubRegistrationTypes()"
                                v-model="selectedRegType"
                            >
                                <option value="null" selected disabled>
                                    --- Select One ---
                                </option>
                                <option
                                    v-for="reg in registrationTypes"
                                    v-show="registrationTypes && registrationTypes.length > 0"
                                    :key="reg.id"
                                    :value="reg.id"
                                >
                                    {{ reg.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4"
                        v-if="subRegTypes && subRegTypes.length > 0"
                    >
                        <base-input alternative=""
                            label="Sub Registration Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.sub_registration_type_id"
                            >
                                <option value="null" selected disabled>
                                    --- Select One ---
                                </option>
                                <option
                                    v-for="reg in subRegTypes"
                                    @if="subRegTypes && subRegTypes.length > 0"
                                    :key="reg.id"
                                    :value="reg.id"
                                >
                                    {{ reg.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input label="Gender">
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.gender"
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input label="Practice Type">
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.doctor_type_id"
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option
                                    v-for="type in record.doctor_types"
                                    :key="type.id"
                                    :value="type.id"
                                >
                                    {{ type.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Full Registration Number"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.registration_no"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Provisional Registration Number"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.provisional_reg_no"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input
                            label="Provisional Registration Date (Date of Prov. Reg)"
                            addon-left-icon="ni ni-calendar-grid-58"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="{allowInput: true}"
                                class="form-control datepicker"
                                v-model="record.provisional_reg_date"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input
                            label="Full Registration Date (Date of Full Registration)"
                            addon-left-icon="ni ni-calendar-grid-58"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="{allowInput: true}"
                                class="form-control datepicker"
                                v-model="record.full_reg_date"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Folio Number"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.folio_number"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Email Address"
                            input-classes="form-control-alternative"
                            v-model="record.user.email"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Phone Number"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.phone"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input
                            label="Date of Doctor Birth(Date which User was born)"
                            addon-left-icon="ni ni-calendar-grid-58"
                            required
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="{allowInput: true}"
                                class="form-control datepicker"
                                v-model="record.date_of_birth"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Place of Birth"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.place_of_birth"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input label="Year of Graduation">
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.year_of_graduation"
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option
                                    v-for="(year, index) in years"
                                    :key="index"
                                    :value="year"
                                >
                                    {{ year }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <base-input alternative=""
                label="Permanent Address">
                <textarea
                    rows="2"
                    class="form-control form-control-alternative"
                    v-model="record.permanent_address"></textarea>
            </base-input>
        </div>

        <div class="col-lg-12">
            <base-input alternative=""
                label="Postal Address (If any)">
                <textarea
                    rows="2"
                    class="form-control form-control-alternative"
                    v-model="record.postal_address"></textarea>
            </base-input>
        </div>

        <div class="col-lg-12" v-if="record.isTemporary">
            <base-input alternative=""
                label="Employer Address">
                <textarea
                    rows="2"
                    class="form-control form-control-alternative"
                    v-model="employerAddress"></textarea>
            </base-input>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <hr class="my-4" />

        <div class="col-lg-12">
            <base-button
                type="success" size="lg"
                @click="updateProfile()"
                :disabled="(privileges && privileges.update == 'no') || ! privileges"
            >
                <i class="fa fa-save"></i>
                Save Changes
            </base-button>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert  

    export default {
        name: 'BasicProfile',
        props: ['record','privileges', 'isHodOrSuper', 'isHodOrSuperOrUnitHead'],
        components: {
            flatPicker
        },
        data() {
            return {
                key: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showProfile: false,
                selectedRegType: null,
                registrationTypes: [],
                subRegTypes: [],
                employerAddress: null
            }
        },
        computed: {
            ...mapGetters(['userPhotoUrl', 'blankImageUrl']),
            years() {
                let startYear = moment().format('YYYY') - 119;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['updateUserProfile', 'getRegistrationTypes', 'getSubRegistrations']),
            setData() {
                this.employerAddress = this.record && this.record.has_additional_record && this.record.has_additional_record.employer_address
                                            ? this.record.has_additional_record.employer_address : null;
            },
            loadRegistrationTypes() {// load reg types
                this.getRegistrationTypes().then((res) => {
                    this.isLoadingBlade = false;
                    let regTypes = [];
                    let doctorType = this.record.doctor_type_id;
                    if(doctorType == 3) {
                        for(let reg of res) {// check if doctor is alternative
                            if(doctorType == 3 && reg.id == 2) {
                                regTypes.push(reg);
                            }
                        }
                        this.loadSubRegTypes();// load sub reg
                        return this.registrationTypes = regTypes;
                    } else {
                        this.loadSubRegTypes();// load sub reg
                        return this.registrationTypes = res;
                    }
                });
            },
            loadSubRegTypes() {// get sub registrations
                this.subRegTypes = [];
                let id = this.record.sub_registration_type_id;
                if(id) {// get sub registrations
                    this.isLoadingBlade = true;
                    this.getSubRegistrations().then((res) => {
                        this.isLoadingBlade = false;
                        this.subRegTypes = res;
                        for(let type of res) {
                            if(type.id == id) {
                                this.selectedRegType = type.registration_type.id;
                            }
                        }
                    }).
                    catch(() => {
                        this.isLoadingBlade = false;
                    });
                }
            },
            getSubRegistrationTypes() {// get reg sub types
                this.subRegTypes = [];
                // this.record.sub_registration_type_id = null;
                for(let reg of this.registrationTypes) {
                    if(reg.id == this.selectedRegType) {
                        let types = [];
                        let doctorType = this.record.doctor_type_id;
                        if(doctorType == 3) {// check if doctor is alternative 
                            for(let subType of reg.has_sub_registrations) {
                                if(doctorType == 3 && subType.id == 9) {
                                    types.push(subType);
                                }
                            }
                        } else {
                            for(let subType of reg.has_sub_registrations) {
                                if(subType.id != 9) {
                                    types.push(subType);
                                }
                            }
                        }
                        let message = 'Kindly select Sub Registration Type before saving';
                        swal.fire('Notice!', message, 'warning');
                        return this.subRegTypes = types;
                    }
                }
            },
            updateProfile() {// update profile
                this.preloader(); // show loading
                if(! this.isHodOrSuperOrUnitHead) {
                    delete this.record.previous_names;// remove previous names
                }                
                this.record.employer_address = this.employerAddress ? this.employerAddress : null;
                this.updateUserProfile(this.record).then((res) => {// get records
                    if(res.status) {
                        swal.fire('Profile updated!', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            getUserPix(data) {// get user photo url
                return data ? this.userPhotoUrl+data.user_id+'/photo/'+data.photo : null;
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();
        },
        mounted() {
            this.loadRegistrationTypes(); // load reg types
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
    .doctor-image {
        height: 200px !important;
        width: 250px !important;
    }
</style>
<style>
.form-control-label {
    color: #525f7f;
    font-size: 0.875rem;
    font-weight: 600;
    width: 100% !important;
}
</style>