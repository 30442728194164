
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">{{ this.$route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! showErrorStatus && ! isLoadingBlade">

                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                label="Folio Number"
                                                placeholder="Enter Folio Number"
                                                input-classes="form-control-alternative"
                                                v-model="record.folio_number"
                                                @change="getUserDetails()"
                                                required
                                            />
                                        </div>

                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                label="Payment Type"
                                            >
                                                <select
                                                    class="form-control form-control-alternative"
                                                    @change="checkForLicense()"
                                                    v-model="record.application_type_id"
                                                    required
                                                >
                                                    <option value="null" selected disabled>
                                                        --- Select One ---
                                                    </option>
                                                    <option
                                                        v-for="type in paymentTypes"
                                                        v-show="paymentTypes && paymentTypes.length > 0"
                                                        :key="type.id"
                                                        :value="type.id"
                                                    >
                                                        {{ type.name }}
                                                    </option>
                                                </select>
                                            </base-input>
                                        </div>

                                        <div class="col-lg-12" v-if="showLicenseYear">
                                            <base-input alternative=""
                                                label="License Year"
                                            >
                                                <select
                                                    class="form-control form-control-alternative"
                                                    v-model="record.license_year"
                                                    required
                                                >
                                                    <option value="null" selected disabled>
                                                        --- Select One ---
                                                    </option>
                                                    <option
                                                        v-for="year in years"
                                                        v-show="years && years.length > 0"
                                                        :key="year"
                                                        :value="year"
                                                    >
                                                        {{ year }}
                                                    </option>
                                                </select>
                                            </base-input>
                                        </div>

                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                label="RRR Number"
                                                input-classes="form-control-alternative"
                                                placeholder="Enter RRR Number"
                                                v-model="payment_ref"
                                                required
                                            />
                                        </div>

                                        <div
                                            class="col-lg-12 text-center"
                                            v-if="showErrorStatus"
                                        >
                                            <base-alert :type="errorType">
                                                <span class="alert-inner--icon">
                                                    <i class="ni ni-like-2"></i>
                                                </span>
                                                <span class="alert-inner--text">
                                                    {{errorMsg}}
                                                </span>
                                            </base-alert>
                                        </div>

                                        <div class="col-lg-12">
                                            <base-button
                                                type="primary" size="lg"
                                                @click="loadPayment()"
                                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                            >
                                                <i class="fa fa-history"></i>
                                                Load Payment
                                            </base-button>
                                        </div>

                                    </div>

                                    <div class="col-lg-6">
                                        <div class="col-lg-12" v-if="user">
                                            <br>
                                            <h3 class="text-capitalize text-danger">
                                                Full Name : &nbsp;&nbsp;
                                                <em>{{ fullName(user) }}</em>
                                            </h3>
                                            <h3
                                                class="text-capitalize text-danger"
                                                v-if="applicationType && applicationType.has_payment"
                                            >
                                                Required Amount : &nbsp;&nbsp;
                                                <em>{{ applicationType.has_payment.amount | formatPrice }}</em>
                                            </h3>
                                        </div>

                                        <!-- import Add Payment Details template here -->
                                        <AddPaymentDetails
                                            v-bind:paymentDetails="paymentDetails"
                                            v-bind:applicationType="applicationType"
                                            v-bind:user="user"
                                            v-bind:record="record"
                                            v-bind:privileges="privileges"
                                            v-bind:removeAddPayment="removeAddPayment"
                                            v-on:removeAddPayment="removeAddPayment"
                                            v-if="showPaymentBlade && ! isLoadingBlade"
                                        />

                                    </div>

                                </div>
                            </div>

                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert
  import AddPaymentDetails from './AddPaymentDetails';
  import axios from 'axios';

  export default {
    name: 'AddPayment',
    components: {
        AddPaymentDetails
    },
    data() {
      return {
        record: {
            folio_number: null,
            application_type_id: null,
            license_year: null
        },
        payment_ref: null,
        applicationType: null,
        user: null,
        paymentTypes: [],
        isLoadingBlade: true,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showLicenseYear: false,
        paymentDetails: [],
        showPaymentBlade: false
      }
    },
    filters: {
        getDateTimeFormat(value) {
            if(value) {
                return moment(value).format('Do MMM, YYYY [at] h:mm:ss a');
            } else {
                return 'N/A';
            }
        },
        formatPrice(value) {
            if(value) {
                let val = (value/1).toFixed(2).replace(',', '.');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
            } else {
                return 'N/A';
            }
        }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == this.$route.path) {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        },
        years() {
            let endYear = moment().format('YYYY') - 119;// start from 1900
            let startYear = parseInt(moment().format('YYYY')) + 10;
            let nextYear = parseInt(moment().format('YYYY')) + 1;
            let years = [];
            years.push(nextYear); //push in current year
            for (let i = startYear; i > endYear; i-- ) {
                if(i != nextYear) {
                    years.push(i);
                }
            }
            return years;
        }
    },
    methods: {
        ...mapActions(['getApplicationTypes', 'getUserProfile', 'getRemitaPaymentStatus']),
        loadApplicationTypes() {// load application types
            this.getApplicationTypes().then((res) => {// get records
                this.isLoadingBlade = false;
                this.paymentTypes = res;
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        getUserDetails() {// get user details
            this.isLoadingBlade = true;
            let folio = this.record.folio_number;
            this.getUserProfile(folio).then((res) => {// get records 
                this.isLoadingBlade = false;
                if(res.status) {
                    this.user = res.user;
                } else {
                    this.record.folio_number = null;
                    let msg = "User was found with this folio number, kindly check and try again";
                    swal.fire('Payment Failed!', msg, 'error');
                }
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        loadPayment() {// load payment
            let rrr = this.payment_ref;
            let status = this.verifyRrrNumber(rrr);
            // console.log(status); 160352882748    250332191070  330204213948 51376 149104 MDCN/R/35628
            if(status) {
                this.preloader();// show loading
                this.isloading = true;
                this.getRemitaPaymentStatus(rrr).then((res) => {// create  
                    this.isloading = false;
                    if(res.status) {// update transaction
                        this.verifyThroughRemita(res.url);
                    } else {                        
                        // let msg = 'An error while verying payment, kindly reload and try again';
                        swal.fire('Payment Failed!', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let message = 'An error while verying payment, kindly reload and try again';
                    swal.fire('Server Failure!', message, 'warning');
                });
            }
        },
        verifyThroughRemita(url) {
            axios.get(url).then((res) => {
                this.isloading = false;
                if(res.status == 200) {
                    let status = this.checkValidRRR(res.data);// check if RRR as not been added
                    this.showPaymentBlade = true;
                    this.payment_ref = null;
                    status ? swal.fire('Payment Verified!', res.msg, 'success') :
                        swal.fire("RRR Added!", "RRR has been added already, check and try again", "warning");
                }
            })
            .catch(() => {//check for failure
                this.isloading = false;
                let msg = 'Payment was not verified, kindly try again';
                swal.fire('Payment Verfication Failed!', msg, 'warning');
            });
        },
        checkValidRRR(record) {// check if RRR as not been added
            let status = this.paymentDetails.filter(function(item){
                return item.rrr == record.rrr;
            });
            if(status.length == 0) {
                this.paymentDetails.push(record);
                return true;
            } else {
                return false;
            }
        },
        verifyRrrNumber(rrr) {
            let data = this.record;
            if(rrr && rrr.length > 10 && data.folio_number && data.application_type_id) {
                return true;
            } else if(rrr && rrr.length < 10) {
                swal.fire("Incomplete RRR!", "Kindly check and enter a valid RRR", "warning");
                return false;
            } else if(! data.folio_number) {
                swal.fire("Invalid Folio!", "Kindly check and enter a valid Folio Number", "warning");
                return false;
            }   else if(! data.application_type_id) {
                swal.fire("Invalid Payment Type!", "Kindly select a payment type", "warning");
                return false;
            } else {
                swal.fire("No RRR!", "Kindly enter a valid RRR, and try again", "warning");
                return false;
            }
        },
        checkForLicense() {// show license year
            let id = this.record.application_type_id;
            for(let type of this.paymentTypes) {
                if(type.id == id) {// get selected application type
                    this.applicationType = type;
                }
            }
            let validIds = [ 7, 15, 18 ];
            return this.showLicenseYear = validIds.includes(id);
        },
        removeAddPayment() {// add payment details template
            this.showPaymentBlade = false;
            this.record.folio_number = null;
            this.record.application_type_id = null;
            this.payment_ref = null;
            this.record.license_year = null;
            this.applicationType = null;
            this.user = null;
            this.paymentDetails = [];
        },
        fullName(user) {// get full name
            let name = user.first_name+' ';
            name += user.other_name ? user.other_name+' ' : '';
            name += user.last_name;
            return user && user.first_name ? name : 'Not found';
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadApplicationTypes(); // load application types
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
