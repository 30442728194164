
<template>
        <div class="table-responsive">
                    
            <table class="table table-bordered">

                <tbody>
                    <td width="30%">
                        <h4>Applicant's New Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.has_change_name ? getFullName(application.has_change_name) : 'N/A' }}
                        </h4>
                    </td>
                </tbody>                      

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button 
                            size="lg"
                            type="success" 
                            :disabled="privileges && privileges.update == 'no'"
                            @click="approveUserApplication(application.id)"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-check"></i> 
                            Approve Application
                        </base-button>

                        <base-button 
                            size="lg"
                            type="danger" 
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-times"></i> 
                            Cancel Approval
                        </base-button>

                        <base-button 
                            size="lg"
                            type="danger" 
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i> 
                            Back To Application
                        </base-button>
                    </td> 
                </tbody>

            </table>

        </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'ChangeOfNameApproval',
    props: ['application', 'privileges', 'loadApplication'],
    data() {
      return {
        code: this.$route.params.code,
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false,
      }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
      ...mapActions(['approveApplication']),
        getFullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.last_name;
            return name;
        },
        approveUserApplication(id) {// approve application
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeApproveApiCall(id);// make api call
                }
            });
        },
        makeApproveApiCall(id) {// approve application
            let data = { id : id, valid_date : null, status : 'approved', admin_id : this.user.id, license_year : null };
            this.preloader();// show loading
            this.approveApplication(data).then((res) => {// approve application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }   
  };
</script>
<style scoped>
    .right {
    float: right;
    }
</style>
